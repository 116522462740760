import R14 from "../core";
export default class CountryDomain extends R14.EntityDomain {
  constructor(key) {
    super({
      name: "country",
      pluralName: "countries",
    });
    this.state = {};
  }
  async validatePhone(iso2Code, phone) {
    let res = await this.api.query(
      `
          query ValidatePhone($iso2Code: String!, $phone: String!) {
            validatePhone(iso2Code: $iso2Code, phone: $phone){
              success
              error
              value
            }
         }`,
      {
        iso2Code,
        phone,
      }
    );
    if (res.errors && res.errors.length) throw new Error(res.errors[0].message);
    return res.data.validatePhone;
  }
  async fetchSelections(filters, options = {}) {
    let filter = {};
    if (filters.search) {
      filter.search = { like: `%${filters.search}%` };
    }
    if (options.currencyUid) {
      filter.currencyUid = { neq: null };
    }
    if (options.signupProduct) {
      filter.signupProducts = { eq: options.signupProduct };
    }
    let currencyUidField = options.currencyUid ? "currencyUid" : "";
    let res = await this.find(
      `
      uid
      name
      ${currencyUidField}
      `,
      {
        page: 1,
        resultsPerPage: options.resultsPerPage || 25,
        filter: filter,
        totalCount: false,
        sort: [
          {
            field: "name",
            order: "ASC",
          },
        ],
        clientUid: false,
      }
    );
    let ret =
      res && res.nodes
        ? res.nodes.map((val) => {
            let ret = {
              label: val.name,
              value: val.uid,
            };
            if (options.currencyUid) ret.currencyUid = val.currencyUid;
            return ret;
          })
        : [];
    return ret;
  }
  // async get(uid, fields) {
  //   if (!fields) fields = [("uid", "name")];
  //   return await super.get(uid, fields);
  // }
}
