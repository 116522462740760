import React from "react";
import R14, {
  StyleSheet,
  Text,
  SubmitButton,
  TextInputField,
  Colors,
  CenteredForm,
  FadeView,
  Redirect,
} from "../core";

export default R14.connect(
  class AccountPasswordEditScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.state = {
        validPassword: true,
      };
    }
    get formData() {
      return this.props.formData;
    }
    async handleSubmit(accountForm) {
      let success = false;
      this.props.app.ui.progressIndicator.show();
      try {
        await this.props.app.dm.login.updateUserPassword(accountForm.values);
        this.props.app.ui.snackBar.show({
          variant: "success",
          message: "Your password has been updated.",
        });
        success = true;
      } catch (err) {
        switch (err.message) {
          case "r14User-password-mismatch":
            accountForm.elmts.password.setError("Incorrect password.");
            break;
          case "r14User-password-previous":
            accountForm.elmts.password.setError("Password already used.");
            break;
          default:
            this.props.app.ui.snackBar.show({
              message: err.message,
              variant: "error",
            });
        }
      }
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      return success ? <Redirect to='deployments' /> : false;
    }
    render() {
      return (
        <CenteredForm
          headerLabel='Update Password'
          name='accountPasswordEditForm'
          onSubmit={this.handleSubmit}
          validateBeforeSubmit
          autoComplete={false}
          controlsBottomRight={[<SubmitButton title={"Update"} key='submit' />]}
          style={styles.centeredForm}
          validators={{
            passwordConfirm: (value, opts, elmt, form) =>
              value === form.elmts.newPassword.value
                ? true
                : { error: "Does not match new password." },
            password: (value, opts, elmt, form) => {
              let validPassword = value.match(
                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{7,16}$/
              );
              this.setState({ validPassword });
              return validPassword ? true : { error: "Password is not valid" };
            },
          }}
        >
          <FadeView
            visible={!this.state.validPassword}
            style={styles.invalidPasswordFadeView}
          >
            <Text>
              Your password must be 7 to 16 characters which contain at least
              one lowercase letter, one uppercase letter, one numeric digit, and
              one special character.
            </Text>
          </FadeView>
          <TextInputField
            autoComplete={false}
            name='password'
            label='Current Password'
            required='Please enter your current password'
            secure
          />
          <TextInputField
            autoComplete={false}
            name='newPassword'
            label='New Password'
            required='Please enter your new password'
            validator='password'
            secure
          />
          <TextInputField
            autoComplete={false}
            name='confirmNewPassword'
            label='Confirm New Password'
            required='Please confirm your new password'
            validator='passwordConfirm'
            secure
          />
        </CenteredForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  success: {
    paddingBottom: 24,
  },
  successText: {
    color: Colors.primary,
  },
  centeredForm: {
    maxWidth: 320,
  },
  invalidPasswordFadeView: {
    ...StyleSheet.padding(0, 0, 16, 0),
  },
  header: {
    fontSize: 14,
    fontWeight: 500,
    color: StyleSheet.color(Colors.onSurface).rgba(0.8),
    paddingBottom: 16,
  },
  sectionHeader: {
    fontSize: 14,
    ...StyleSheet.padding(0, 0, 16, 0),
  },
});
