import HeaderControlsRight from "../components/HeaderControlsRight";
import HeaderControlsLeft from "../components/HeaderControlsLeft";
export default {
  initialRoute: "main",
  routes: {
    main: {
      initialRoute: "helloWorld",
      type: "stack",
      header: {
        // title: 'R14 Dev App',
        controlsLeft: HeaderControlsLeft,
        controlsRight: HeaderControlsRight,
      },
      routes: {
        router: {
          path: "/",
          metadata: {
            public: true,
          },
        },
        login: {
          path: "/login",
          metadata: {
            public: true,
          },
        },
        forbidden: {
          path: "/forbidden",
          metadata: {
            public: true,
          },
        },
        error: {
          path: "/error",
          metadata: {
            public: true,
          },
        },
        loginVerify: {
          path: "/verify",
          metadata: {
            public: true,
          },
        },
        signup: {
          path: "/signup",
          metadata: {
            public: true,
          },
        },
        signupVerifyEmail: {
          path: "/signup/verify/email",
          metadata: {
            signup: true,
          },
        },
        signupVerifyPhone: {
          path: "/signup/verify/phone",
          metadata: {
            signup: true,
          },
        },
        signupPaymentInfo: {
          path: "/signup/payments",
          metadata: {
            signup: true,
          },
        },
        signupPendingApproval: {
          path: "/signup/pending",
          metadata: {
            signup: true,
          },
        },
        deployments: {
          path: "/deployments",
          metadata: {
            public: false,
          },
        },
        accountPasswordEdit: {
          path: "/account/password",
          metadata: {
            public: false,
          },
        },
      },
    },
    modal: {
      type: "modal",
      initialRoute: "helloWorldUpdate",
      modal: {},
      header: {},
      routes: {
        helloWorldUpdate: {
          title: "Update Hello World",
          path: "/helloWorld/update",
          initialParentRoute: "helloWorld",
        },
      },
    },
  },
};
