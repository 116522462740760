import React from "react";
import R14, {
  Redirect,
  Button,
  Touchable,
  StyleSheet,
  Text,
  View,
  SectionHeader,
  Colors,
  Surface,
  CenteredCard,
} from "../core";
export default R14.connect(
  class SignupPendingApprovalScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      let productTitle = this.props.app.dm.login.getR14ProductTitle({
        capitalize: true,
      });
      return (
        <CenteredCard title='Pending Approval' style={styles.centeredCard}>
          <Text>
            You're registration for {productTitle} is complete. We are reviewing your information and will contact you shortly.
          </Text>
        </CenteredCard>
      );
    }
  }
);

const styles = StyleSheet.create({
  centeredCard: {
    maxWidth: 448,
  },
});
