import LoginDomain from '../domains/LoginDomain';
import CountryDomain from '../domains/CountryDomain';
import BankDomain from '../domains/BankDomain';
export default {
  domain: {
    login: LoginDomain,
    country: CountryDomain,
    bank: BankDomain,
  },
  ui: {}
}