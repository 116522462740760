import React from "react";
import R14, { StyleSheet, SelectMenuField } from "../core";
export default R14.connect(
  class CountrySelectMenuField extends React.Component {
    constructor(props) {
      super(props);
      this.itemLoader = this.itemLoader.bind(this);
    }
    async itemLoader(filters = {}) {
      return await this.props.app.dm.country.fetchSelections(filters, {
        resultsPerPage: this.props.resultsPerPage || 25,
        currencyUid: this.props.currencyUid ? true : false,
        signupProduct: this.props.signupProduct || null,
      });
    }
    _getDefaultPropValue(prop, value, multipleValue) {
      return this.props[prop] || (this.props.multiple ? multipleValue : value);
    }
    get name() {
      return this._getDefaultPropValue("name", "countryUid", "countryUids");
    }
    get key() {
      return this._getDefaultPropValue("key", "countryUid", "countryUids");
    }
    get label() {
      return this._getDefaultPropValue("key", "Country", "Countries");
    }
    get name() {
      return (
        this.props.name || (this.props.multiple ? "countryUids" : "countryUid")
      );
    }
    render() {
      return (
        <SelectMenuField
          key={this.key}
          name={this.name}
          label={this.label}
          {...this.props}
          itemLoader={this.itemLoader}
        />
      );
    }
  }
);
const styles = StyleSheet.create({});
