const getBaseUrl = () => {
  if (!window || !window.location) return "";
  let url = `${window.location.protocol}//${window.location.hostname}`;
  if (window.location.port) url = `${url}:${window.location.port}`;
  return url;
};
const getR14Product = (url) => {
  let ret = null;
  let productMap = { INVENTR: ["inventr.ai"], TELETYPE: ["teletype.team"] };
  for (let product in productMap) {
    let match = productMap[product].filter((p) => url.indexOf(p) !== -1);
    if (match.length) {
      ret = product;
      break;
    }
  }
  return ret;
};

let baseUrl = getBaseUrl();
let r14Product = getR14Product(baseUrl);
// let devBaseUrl = "http://r14dev.com";
let devBaseUrl = baseUrl;
if (!baseUrl)
  throw new Error("R14 App Error: Api url not set in app.config.js.");
const Config = {
  name: "r14-login-app",
  api: {
    url: process.env.NODE_ENV === "development" ? devBaseUrl : baseUrl,
    // onAuthError: async (app) => await app.dm.userSession.handleAuthError(),
    onClientError: async (code, app) =>
      await app.dm.userSession.handleClientError(code),
  },
  io: {
    url: process.env.NODE_ENV === "development" ? devBaseUrl : baseUrl,
  },
  metadata: {
    r14: {
      product: r14Product,
    },
    reCaptchaKey:
      process.env.NODE_ENV === "development"
        ? "6LdUm1EnAAAAAKIAPwx79xdfwt5VKieYUm4ZRZdO"
        : "6LecplInAAAAABqCFvxzpfttqlPBoTH6eQVoUKeT",
  },
};
export default Config;
