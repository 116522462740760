import React from "react";
import R14, {
  Redirect,
  CenteredForm,
  TextInputField,
  SubmitButton,
  StyleSheet,
  View,
  Text,
  SectionHeader,
  Touchable,
  Colors,
} from "../core";
import CountrySelectMenuField from "../components/CountrySelectMenuField";
export default R14.connect(
  class SignupScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.product = this.props.app.dm.login.getR14Product();
    }
    async handleSubmit(signupForm) {
      try {
        this.props.app.ui.progressIndicator.show();
        let authState = await this.props.app.dm.login.signupUser(
          signupForm.values
        );
        let redirectRoute = null;
        let forwardUrl = null;
        // switch (authState) {
        //   case this.props.app.dm.login.AUTH_STATE_LOGGED_IN:
        //     let r14Deployments = this.props.app.dm.login.session.r14Deployments;
        //     if (!r14Deployments.length)
        //       throw new Error("No deployment data found.");
        //     else if (r14Deployments.length === 1)
        //       forwardUrl = `${r14Deployments[0].url}/auth`;
        //     else redirectRoute = "deployments";
        //     break;
        //   case this.props.app.dm.login.AUTH_STATE_VERIFY_MFA_CODE:
        //     redirectRoute = "loginVerify";
        //     break;
        // }
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });

        return <Redirect to='router' />;
      } catch (err) {
        switch (err.message) {
          case "EMAIL_EXISTS":
            signupForm.elmts.email.setError("Email already exists");
            break;
          case "USERNAME_EXISTS":
            signupForm.elmts.username.setError("Username already exists");
            break;
            case "AUTH_CODE_SEND_ERROR":
              signupForm.elmts.email.setError("Error sending email authentication code");
            break;
          default:
            console.error(err);
            signupForm.addError("An unknown signup error has occurred.");
        }
      }
    }
    renderLoginIdentifierField() {
      let loginIdentifierField = null;
      switch (this.product) {
        case this.props.app.dm.login.PRODUCT_INVENTR:
          // loginIdentifierField = (
          //   <TextInputField
          //     name='email'
          //     label='Email'
          //     required='Enter Email'
          //     validator='email'
          //     autoFocus
          //   />
          // );
          loginIdentifierField = null;
          break;
        case this.props.app.dm.login.PRODUCT_TELETYPE:
          loginIdentifierField = (
            <TextInputField
              name='username'
              label='Username'
              required='Enter Username'
              validator='username'
              helper='Letters and Numbers only. No spaces.'
            />
          );
          break;
        default:
          throw new Error("Unknown Product.");
      }
      return loginIdentifierField;
    }
    render() {
      if (
        this.props.app.dm.login.getR14Product() !==
        this.props.app.dm.login.PRODUCT_TELETYPE
      )
        return <Redirect to='login' />;
      return (
        <CenteredForm
          name='signupForm'
          style={styles.centeredForm}
          headerLabel='Sign Up'
          validateBeforeSubmit
          onSubmit={this.handleSubmit}
          controlsBottomRight={[
            // <Button
            //   title="Forgot Password"
            //   variant="text"
            //   color={Colors.secondary}
            //   to='forgotPassword'
            //   key='forgotPassword'
            // />,
            <SubmitButton title='Continue' key='submit' />,
          ]}
        >
          <Touchable style={styles.loginTouchable} to='login'>
            <Text>
              Already have an account?{" "}
              <Text style={styles.loginTouchableText}>Login</Text>
            </Text>
          </Touchable>
          <View style={styles.infoTextView}>
            <Text>
              Please enter your information below. You will then be asked to
              verify your email address before approval.
            </Text>
          </View>

          <SectionHeader level={3}>Country</SectionHeader>
          <CountrySelectMenuField
            name='country'
            searchable
            autoLoad
            signupProduct={this.props.app.dm.login.getR14Product()}
            required='Please select a country'
          />
          <SectionHeader level={3}>Contact</SectionHeader>
          <TextInputField
            name='name'
            label='Name'
            autoFocus
            required='Enter Name'
          />
          <TextInputField
            name='email'
            label='Email'
            required='Enter Email'
            validator='email'
          />
          <SectionHeader level={3}>Account</SectionHeader>
          {this.renderLoginIdentifierField()}
          <TextInputField
            name='password'
            label='Password'
            secure
            required='Enter Password'
            validator='password'
            autoComplete={false}
          />
          <TextInputField
            name='passwordConfirm'
            label='Confirm Password'
            secure
            required='Confirm Password'
            validator='passwordConfirm'
          />
        </CenteredForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  centeredForm: {
    width: 320,
    minWidth: 320,
    screen: ({ width }) => {
      if (width <= 320)
        return {
          width: "100%",
        };
    },
  },
  infoTextView: {
    paddingBottom: 24,
  },
  loginTouchable: {
    ...StyleSheet.padding(0, 0, 16, 0),
  },
  loginTouchableText: {
    textDecorationLine: "underline",
    color: Colors.primary,
  },
});
