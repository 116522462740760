import React from "react";
import R14, {
  View,
  IconButton,
  StyleSheet,
  Theme,
  Colors,
  PopUpMenu,
  PopUpMenuItem,
  Image,
  Platform
} from "../core";

export default R14.connect(
  class HeaderControlsLeft extends React.Component {
    constructor(props) {
      super(props);
      this.product = this.props.app.dm.login.getR14Product();
    }
    componentDidMount() {
      this.initProduct();
     
    }
    initProduct(){
      if(Platform.OS !== 'web') return;
      const favicon = document.getElementById("favicon");
      favicon.href = `favicon-${this.product.toLowerCase()}.ico`;
      document.title = this.product.toLowerCase();
    }
    render() {
      let logoColorScheme = "onDark";
      let logoHeight = 56;
      let logoMobileHeight = 48;
      let logo = require(`../assets/images/${this.product.toLowerCase()}_${logoColorScheme}_logo.png`);

      switch (this.product) {
        case this.props.app.dm.login.PRODUCT_INVENTR:
        case this.props.app.dm.login.PRODUCT_TELETYPE:
          // Do nothing
          break;
        default:
          throw new Error("Unknown Product.");
      }

      const logoStyles = StyleSheet.create({
        logoImage: {
          height: logoHeight,
          screen: ({ width }) => {
            if (width <= 640)
              return {
                height: logoMobileHeight,
              };
          },
        },
      });
      return (
        <View style={[styles.headerControlsRight]}>
          {logo && (
            <Image
              resizeMode='contain'
              style={[styles.logoImage, logoStyles.logoImage]}
              source={logo}
            />
          )}
        </View>
      );
    }
  }
);

const styles = StyleSheet.create({
  headerControlsRight: {
    flex: 1,
    flexDirection: "row",
    // paddingRight: 6,
    paddingLeft: 8,
    alignItems: "center",
    paddingLeft: 16,
  },
  headerNoDrawer: {},
  logoImage: {
    height: 56,
    width: 144,
    backgroundPosition: "center",
    // height: 40,
    // width: 160,
    screen: ({ width }) => {
      if (width <= 640)
        return {
          height: 48,
          width: 144,
        };
    },
  },
  menuIcon: {
    ...StyleSheet.padding(12, 12, 12, 12),
    height: 48,
    ...StyleSheet.margin(0, 8, 0, 0),
    screen: ({ width }) => {
      if (width <= 640)
        return {
          ...StyleSheet.padding(12, 12, 12, 12),
          ...StyleSheet.margin(0, 8, 0, 0),
        };
    },
  },
});
