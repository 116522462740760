import R14, { AsyncSessionStorage, AsyncStorage } from "../core";

export default class LoginDomain extends R14.Domain {
  constructor() {
    super();
    this.PRODUCT_INVENTR = "INVENTR";
    this.PRODUCT_TELETYPE = "TELETYPE";
    this.AUTH_STATE_LOGGED_OUT = "LOGGED_OUT";
    this.AUTH_STATE_LOGGED_IN = "LOGGED_IN";
    this.AUTH_STATE_VERIFY_MFA_CODE = "VERIFY_MFA_CODE";
    this.AUTH_STATE_UPDATE_PASSWORD = "UPDATE_PASSWORD";
    this.AUTH_STATE_SIGNUP = "SIGNUP";

    this.USER_STATE_ACTIVE = "SIGNUP_ACTIVE";
    this.USER_STATE_INACTIVE = "SIGNUP_INACTIVE";
    this.USER_STATE_SIGNUP_VERIFY_EMAIL = "SIGNUP_VERIFY_EMAIL";
    this.USER_STATE_SIGNUP_VERIFY_PHONE = "SIGNUP_VERIFY_PHONE";
    this.USER_STATE_SIGNUP_PAYMENT_INFO = "SIGNUP_PAYMENT_INFO";
    this.USER_STATE_SIGNUP_PENDING_APPROVAL = "SIGNUP_PENDING_APPROVAL";

    this.state = {
      user: null,
      authState: this.AUTH_STATE_LOGGED_OUT,
      loggedIn: false,
      r14Deployments: [],
      test: "test"
    };
  }
  async domainDidLoad() {
    return await this.load();
  }
  async load() {
    let authState = await AsyncStorage.getItem(`authState`);
    if (authState && authState !== this.AUTH_STATE_LOGGED_OUT) {
      try {
        await this.auth();
      } catch (err) {
        console.error(err);
        // Clear any storage to force new login
        await AsyncStorage.clear();
      }
    }
    return true;
  }
  getR14Product() {
    // return this.PRODUCT_TELETYPE;
    if (
      !this.r14.config.metadata ||
      !this.r14.config.metadata.r14 ||
      !this.r14.config.metadata.r14.product
    )
      throw new Error("Product not found.");
    return this.r14.config.metadata.r14.product;
  }
  getR14ProductTitle(options = {}) {
    let title = null;
    switch (this.getR14Product()) {
      case this.PRODUCT_INVENTR:
        title = "inventr";
        break;
      case this.PRODUCT_TELETYPE:
        title = "teletype";
        break;
      default:
        title = "N/A";
    }
    if (options.capitalize) title = this.utils.str.capitalize(title);
    return title;
  }
  setLoggedIn(loggedIn) {
    this.setState({
      loggedIn: loggedIn,
    });
  }
  get isLoggedIn() {
    return this.state.loggedIn;
  }
  get mfaAccessTokenExists() {
    return this.state.mfaAccessToken ? true : false;
  }
  get mfaAccessToken() {
    return this.state.mfaAccessToken;
  }
  get authState() {
    return this.state.authState;
  }
  get user() {
    return this.state.user;
  }
  setAuthState(authState) {
    this.setState({ authState });
  }
  setMfaAccessToken(mfaAccessToken) {
    AsyncSessionStorage.setItem("mfaAccessToken", mfaAccessToken || "");
    this.setState({ mfaAccessToken });
  }
  async deleteMfaAccessToken() {
    await AsyncSessionStorage.removeItem("mfaAccessToken");
    this.setState({ mfaAccessToken: undefined });
  }
  get r14Deployments() {
    return this.state.r14Deployments || [];
  }
  get session() {
    return {
      user: this.state.user,
      r14Deployments: this.state.r14Deployments || [],
    };
  }
  // MFA Code Verification
  async resendAuthCode() {
    // let mfaAccessToken = this.mfaAccessToken;
    // if (!mfaAccessToken) throw new Error("Token not found.");
    let res = await this.api.mutate(
      `
        mutation ResendUserAuthCode {
          resendUserAuthCode {
            success
            error
          }
       }`,
      {}
      // { accessToken: mfaAccessToken }
    );
    return true;
  }
  // MFA Code Verification
  async verifyAuthCode({ authCode }) {
    // let mfaAccessToken = this.mfaAccessToken;
    // if (!mfaAccessToken) throw new Error("Token not found.");
    let res = await this.api.mutate(
      `
        mutation VerifyAuthCode($input: VerifyUserAuthCodeInput!) {
          verifyUserAuthCode(input: $input){
            user {
              uuid
              name
              email
              username
              state
              country {
                uid
                name
                iso2Code
              }
            }
            r14Deployments {
              uuid
              name
              url
            }
            state
            accessToken
            success
            error
          }
       }`,
      {
        input: {
          authCode,
        },
      }
      // { accessToken: mfaAccessToken }
    );
    if (
      res.data &&
      res.data.verifyUserAuthCode &&
      res.data.verifyUserAuthCode.error
    )
      throw new Error(res.data.verifyUserAuthCode.error);
    else if (res.error && res.errors.length) {
      throw new Error(res.error[1]);
    } else if (!res.data.verifyUserAuthCode) {
      throw new Error("Unknown error");
    }
    return await this._processAuthResult(res.data.verifyUserAuthCode);
  }
  // async deploymentForward(r14DeploymentUuid) {
  //   let res = await this.api.mutate(
  //     `
  //       mutation ForwardDeployment($input: ForwardDeploymentInput!) {
  //         forwardDeployment(input: $input) {
  //           forwardUrl
  //           success
  //           error
  //         }
  //      }`,
  //     { input: { r14DeploymentUuid: r14DeploymentUuid } }
  //   );
  //   console.log("deploymentForward res", res.data, res.errors);
  //   return true;
  // }
  async authUser(loginData) {
    let input = {
      password: loginData.password,
      reCaptchaToken: loginData.reCaptchaToken,
    };
    if (loginData.username) input.username = loginData.username;
    else if (loginData.email) input.email = loginData.email;
    else throw new Error("Username / email error.");
    input.product = this.getR14Product();
    let res = await this.api.mutate(
      `
        mutation AuthUser($input: AuthUserInput!) {
          authUser(input: $input){
            user {
              uuid
              name
              email
              username
              state
              country {
                uid
                name
                iso2Code
              }
            }
            state
            accessToken
            success
            error
            product
            r14Deployments {
              uuid
              name
              url
            }
          }
       }`,
      {
        input,
      }
    );
    if (res.data && res.data.authUser && res.data.authUser.error)
      throw new Error(res.data.authUser.error);
    else if (res.errors && res.errors.length)
      throw new Error(res.errors[0].message);
    return await this._processAuthResult(res.data.authUser);
  }
  async logoutUser() {
    let res = await this.api.mutate(
      `
        mutation LogoutUser {
          logoutUser {
            state
            success
            error
          }
        }`,
      {}
    );
    if (res.data && res.data.logoutUser && res.data.logoutUser.error)
      throw new Error(res.data.logoutUser.error);
    else if (res.errors && res.errors.length)
      throw new Error(res.errors[0].message);

    if (res.data.logoutUser.success) {
      this.setState({
        user: null,
        authState: this.AUTH_STATE_LOGGED_OUT,
        loggedIn: false,
        r14Deployments: [],
      });
      await AsyncStorage.clear();
    }
    return res.data.logoutUser;
  }
  async auth() {
    let res = await this.api.mutate(
      `
        mutation Auth {
          auth {
            user {
              uuid
              name
              email
              username
              state
              country {
                uid
                name
                iso2Code
              }
            }
            state
            accessToken
            success
            error
            product
            r14Deployments {
              uuid
              name
              url
            }
          }
       }`
      // {
      //   input,
      // }
    );
    if (res.data && res.data.auth && res.data.auth.error)
      throw new Error(res.data.auth.error);
    else if (res.errors && res.errors.length)
      throw new Error(res.errors[0].message);
    return await this._processAuthResult(res.data.auth);
  }
  get userFields() {
    const fields = ["uuid", "name", "username", "email"];
    return fields;
  }
  async updateUserPassword({ password, newPassword }) {
    let res = await this.api.mutate(
      `
        mutation UpdateUserPassword($input: UpdateUserPasswordInput!) {
          updateUserPassword(input: $input){
            user {
              uuid
              name
              email
              username
              state
              country {
                uid
                name
                iso2Code
              }
            }
            state
            accessToken
            success
            error
            product
            r14Deployments {
              uuid
              name
              url
            }
          }
       }`,
      {
        input: {
          password,
          newPassword,
        },
      }
    );
    if (
      res.data &&
      res.data.updateUserPassword &&
      res.data.updateUserPassword.error
    )
      throw new Error(res.data.updateUserPassword.error);
    else if (res.errors && res.errors.length) {
      throw new Error(res.errors[0].message);
    } else if (!res.data.updateUserPassword) {
      throw new Error("Unknown error");
    }
    return await this._processAuthResult(res.data.updateUserPassword);
  }
  async _processAuthResult(res) {
    if (!res.user || !res.success)
      throw new Error("Unknown Auth Error: No Access.");
    let user = res.user;
    if (!user || !user.uuid) new Error("Unknown Auth Error: User not found.");
    let loginState = {
      user,
      authState: res.state,
      loggedIn: res.state === this.AUTH_STATE_LOGGED_IN,
      r14Deployments: res.r14Deployments || [],
      // mfaAccessToken: undefined,
    };

    // switch (res.state) {
    //   case this.AUTH_STATE_LOGGED_IN:
    //     // Create the async storage data
    //     // for (let field of this.userFields) {
    //     //   await AsyncStorage.setItem(`r14User_${field}`, user[field] || "");
    //     // }
    //     // await AsyncStorage.setItem(
    //     //   `r14User_r14Deployments`,
    //     //   JSON.stringify(res.r14Deployments || [])
    //     // );
    //     break;
    //   case this.AUTH_STATE_VERIFY_MFA_CODE:
    //     // await this.api.deleteAccessToken();
    //     // if (!res.accessToken) throw new Error("Verify access token not found.");
    //     // loginState.mfaAccessToken = res.accessToken;
    //     // await AsyncStorage.setItem(`mfaAccessToken`, res.accessToken);
    //     break;
    //   default:
    //     throw new Error("Unknown authentication state.");
    // }
    await AsyncStorage.setItem(`authState`, res.state);
    this.setState(loginState);
    return res.state || this.AUTH_STATE_LOGGED_OUT;
  }

  //UpdateUserPaymentInfoInput
  async signupUser(signupData) {
    let input = this.parseUserSignupFormValues(signupData);
    // if (loginData.username) input.username = loginData.username;
    // else if (loginData.email) input.email = loginData.email;
    // else throw new Error("Username / email error.");
    input.product = this.getR14Product();
    if (input.passwordConfirm) delete input.passwordConfirm;

    let res = await this.api.mutate(
      `
        mutation SignupUser($input: SignupUserInput!) {
          signupUser(input: $input){
            user {
              uuid
              name
              email
              username
              state
              country {
                uid
                name
                iso2Code
              }
            }
            state
            accessToken
            success
            error
            product
          }
       }`,
      {
        input,
      }
    );
    if (res.data && res.data.signupUser && res.data.signupUser.error)
      throw new Error(res.data.signupUser.error);
    else if (res.errors && res.errors.length)
      throw new Error(res.errors[0].message);
    return await this._processAuthResult(res.data.signupUser);
  }

  parseUserSignupFormValues(values) {
    let ret = { ...values };
    if (ret.country) {
      ret.countryUid = ret.country.value;
      delete ret.country;
    }
    return ret;
  }
  get customUserAddressKey() {
    return "custom-user-address";
  }
  get userAddressFields() {
    return {
      googlePlace: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      country: null,
      county: null,
      postalCode: null,
    };
  }
  parseUserPaymentInfoFormValues(values) {
    let ret = { ...values };
    if (!values.address1) throw new Error("No address found");
    // if (ret.address) {
    //   ret.googlePlaceId = ret.address.value;
    //   delete ret.address;
    // }
    // Get the address field values
    ret.address = this.userAddressFields;
    for (let addressField in this.userAddressFields) {
      if (!(addressField in ret) || addressField === "googlePlace") continue;
      switch (addressField) {
        case "address1":
          ret.address[addressField] = values[addressField].label;
          ret.googlePlaceId =
            values[addressField].value !== this.customUserAddressKey
              ? values[addressField].value
              : null;
          break;
        default:
          ret.address[addressField] = values[addressField];
      }
      delete ret[addressField];
    }
    delete ret.address.googlePlace;

    if (ret.bank) {
      ret.bankUid = ret.bank.value;
      delete ret.bank;
    }
    if (ret.dateOfBirthMonth) {
      ret.dateOfBirth = `${ret.dateOfBirthMonth}/${ret.dateOfBirthDay}/${ret.dateOfBirthYear}`;
      delete ret.dateOfBirthMonth;
      delete ret.dateOfBirthDay;
      delete ret.dateOfBirthYear;
    }
    if (values.googlePlace)
      ret.googlePlace = {
        formatted_address: values.googlePlace.formatted_address,
        place_id: values.googlePlace.place_id,
        address_components: values.googlePlace.address_components,
      };

    ret.uuid = this.session.user.uuid;
    ret.product = this.getR14Product();
    return ret;
  }

  async updateUserPaymentInfo(values) {
    let input = this.parseUserPaymentInfoFormValues(values);
    // if (loginData.username) input.username = loginData.username;
    // else if (loginData.email) input.email = loginData.email;
    // else throw new Error("Username / email error.");
    let res = await this.api.mutate(
      `
        mutation UpdateUserPaymentInfo($input: UpdateUserPaymentInfoInput!) {
          updateUserPaymentInfo(input: $input){
            user {
              uuid
              name
              email
              username
              state
              country {
                uid
                name
                iso2Code
              }
            }
            state
            success
            error
            product
          }
       }`,
      {
        input,
      }
    );
    if (
      res.data &&
      res.data.updateUserPaymentInfo &&
      res.data.updateUserPaymentInfo.error
    )
      throw new Error(res.data.updateUserPaymentInfo.error);
    else if (res.errors && res.errors.length)
      throw new Error(res.errors[0].message);
    return await this._processAuthResult(res.data.updateUserPaymentInfo);
  }
}
