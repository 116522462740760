import React from "react";
import R14, {
  Redirect,
  Button,
  Touchable,
  StyleSheet,
  Text,
  View,
  SectionHeader,
  Colors,
  Surface,
  CenteredCard
} from "../core";
export default R14.connect(
  class DeploymentsScreen extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      let deployments = this.props.app.dm.login.r14Deployments;
      return (
        <CenteredCard title='Select a deployment...' style={styles.centeredCard}>
          <View style={styles.wrapper}>
          {deployments
            .map((deployment) => {
              return (
                <Touchable
                  style={styles.serviceTouchable}
                  key={deployment.uuid}
                  onPress={() =>  this.props.app.nav.toUrl(`${deployment.url}/auth`)}
                >
                  <Surface elevation={2} style={styles.serviceSurface}>
                    <Text>{deployment.name}</Text>
                  </Surface>
                </Touchable>
              );
            })
            }
          </View>
        </CenteredCard>
      );
    }
  }
);

const styles = StyleSheet.create({
  centeredCard: {
    maxWidth: 448,
  },
  wrapper: {
    marginBottom: -16,
  },
  serviceTouchable: {
    marginBottom: 12,
  },
  sectionHeader: {
    fontSize: 14,
    fontWeight: 500,
    color: StyleSheet.color(Colors.onSurface).rgba(0.8),
    paddingBottom: 16,
  },
  serviceSection: {
    paddingBottom: 12,
  },
  serviceSurface: {
    // borderWidth: 1,
    // borderStyle: "solid",
    borderRadius: 4,
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.05),
  },
  serviceSectionHeader: {
    fontSize: 14,
    ...StyleSheet.padding(0, 0, 16, 0),
  },
});
