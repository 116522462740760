import React from "react";
import R14, {
  Redirect,
  CenteredForm,
  TextInputField,
  SubmitButton,
  StyleSheet,
  Button,
  Colors,
  ReCaptchaField,
} from "../core";
export default R14.connect(
  class LoginScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.product = this.props.app.dm.login.getR14Product();
    }
    async handleSubmit(loginForm) {
      try {
        this.props.app.ui.progressIndicator.show();

        let params = this.props.f ? { f: this.props.f } : null;
        let authState = await this.props.app.dm.login.authUser(
          loginForm.values
        );

        // let redirectRoute = null;
        // let forwardUrl = null;
        // switch (authState) {
        //   case this.props.app.dm.login.AUTH_STATE_LOGGED_IN:
        //     let r14Deployments = this.props.app.dm.login.session.r14Deployments;
        //     if (!r14Deployments.length)
        //       throw new Error("No deployment data found.");
        //     else if (r14Deployments.length === 1)
        //       forwardUrl = `${r14Deployments[0].url}/auth`;
        //     else redirectRoute = "deployments";
        //     break;
        //   case this.props.app.dm.login.AUTH_STATE_VERIFY_MFA_CODE:
        //     redirectRoute = "loginVerify";
        //     break;
        //   case this.props.app.dm.login.AUTH_STATE_SIGNUP:
        //     switch(this.props.app.dm.login.session.user.state){
        //       case this.props.app.dm.login.USER_STATE_SIGNUP_VERIFY_EMAIL:
        //         redirectRoute = "signupVerifyEmail";
        //         break;
        //     }

        //     break;
        // }
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });

        return <Redirect to='router' />;

        // if (forwardUrl) {
        //   this.props.app.nav.toUrl(forwardUrl);
        //   return false;
        // } else
        //   return redirectRoute ? (
        //     <Redirect to={redirectRoute} params={params} />
        //   ) : (
        //     false
        //   );
      } catch (err) {
        console.error(err);

        this.props.app.ui
          .form("loginForm")
          .addError("Error logging in. Please check username / password.");
      }
    }
    renderLoginIdentifierField() {
      let loginIdentifierField = null;
      switch (this.product) {
        case this.props.app.dm.login.PRODUCT_INVENTR:
          loginIdentifierField = (
            <TextInputField
              name='email'
              label='Email'
              icon='account'
              required='Enter Email'
              validator='email'
              autoFocus
            />
          );
          break;
        case this.props.app.dm.login.PRODUCT_TELETYPE:
          loginIdentifierField = (
            <TextInputField
              name='username'
              label='Username'
              icon='account'
              required='Enter Username'
              validator='username'
              autoFocus
            />
          );
          break;
        default:
          throw new Error("Unknown Product.");
      }
      return loginIdentifierField;
    }
    render() {
      return (
        <CenteredForm
          name='loginForm'
          style={styles.centeredForm}
          headerLabel='Login'
          validateBeforeSubmit
          onSubmit={this.handleSubmit}
          controlsBottomRight={[
            this.product === this.props.app.dm.login.PRODUCT_TELETYPE && (
              <Button
                title='Sign Up'
                variant='text'
                // color={Colors.secondary}
                to='signup'
                key='signup'
              />
            ),
            <SubmitButton title='Continue' key='submit' />,
          ]}
        >
          {this.renderLoginIdentifierField()}
          <TextInputField
            name='password'
            label='Password'
            icon='lock'
            secure
            required='Enter Password'
            debug='password'
          />
          <ReCaptchaField
            name='reCaptchaToken'
            siteKey={R14.getInstance().app.config.metadata.reCaptchaKey}
            theme='dark'
            required='Please check reCAPTCHA'
          />
        </CenteredForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  centeredForm: {
    width: 336,
    minWidth: 336,
    screen: ({ width }) => {
      if (width <= 336)
        return {
          width: "100%",
        };
    },
  },
});
