import React from "react";
import R14, {
  View,
  Text,
  IconButton,
  Icon,
  StyleSheet,
  SwitchField,
  Form,
  Colors,
  PopUpMenu,
  PopUpMenuItem,
  Theme,
} from "../core";

export default R14.connect(
  class HeaderControlsRight extends React.Component {
    constructor(props) {
      super(props);
      this.handleLogoutPress = this.handleLogoutPress.bind(this);
      this.handleDeploymentsPress = this.handleDeploymentsPress.bind(this);
      this.handleUpdatePassword = this.handleUpdatePassword.bind(this);
    }
    async handleLogoutPress() {
      this.props.app.ui.progressIndicator.show();
      await this.props.app.dm.login.logoutUser();
      this.props.app.nav.to("login");
      this.props.app.ui.progressIndicator.hide({ timeout: 750 });
    }

    handleDeploymentsPress() {
      this.props.app.nav.to("deployments");
    }
    handleUpdatePassword(){
      this.props.app.nav.to("accountPasswordEdit");
    }
    renderPopUpMenu() {
      let isLoggedIn = false;
      switch (this.props.app.dm.login.authState) {
        case this.props.app.dm.login.AUTH_STATE_LOGGED_IN:
          isLoggedIn = true;
          break;
        case this.props.app.dm.login.AUTH_STATE_VERIFY_MFA_CODE:
        case this.props.app.dm.login.AUTH_STATE_SIGNUP:
          break;
        default:
          return <IconButton to='login' icon='lock' color={Colors.onPrimary} />;
      }
      let items = [];
      let userSession = this.props.app.dm.login.session.user;
      if (!userSession) return null;
      items.push(
        <View key='header' style={styles.header}>
          <View style={styles.headerName}>
            <Text>
              {userSession.name || userSession.username || userSession.email}
            </Text>
          </View>
          {userSession.name && (
            <View style={styles.headerLabel}>
              <Text style={styles.headerLabelText}>
                {userSession.username || userSession.email}
              </Text>
            </View>
          )}
        </View>
      );

      if (isLoggedIn) {
        items.push(
          <PopUpMenuItem
            icon='accountGroup'
            iconSize='small'
            label='Deployments'
            onPress={this.handleDeploymentsPress}
            key='deployments'
          />
        );
      }
      items.push(
        <PopUpMenuItem
          icon='account'
          iconSize='small'
          label='Update Password'
          onPress={this.handleUpdatePassword}
          key='updatePassword'
        />
      );
      items.push(
        <PopUpMenuItem
          icon='lock'
          iconSize='small'
          label='Logout'
          onPress={this.handleLogoutPress}
          key='logout'
        />
      );
      return (
        <PopUpMenu
          controlIcon='accountCircle'
          controlIconColor={
            Theme.componentColors("header", {
              default: Colors.onPrimary,
            }).onPrimary
          }
          key='headerControlsLeftMenu'
          direction='downLeft'
          style={styles.accountMenu}
          onSelect={(value, label) => {}}
        >
          {items}
        </PopUpMenu>
      );
    }
    render() {
      return (
        <View style={styles.headerControlsRight}>{this.renderPopUpMenu()}</View>
      );
    }
  }
);

const styles = StyleSheet.create({
  headerControlsRight: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    paddingRight: 6,
    paddingLeft: 6,
  },
  accountMenu: {
    width: 256,
    maxHeight: 320,
  },
  header: {
    flex: 1,
    flexDirection: "column",
    borderBottomWidth: 1,
    borderColor: StyleSheet.color(Colors.onSurface).rgba(0.1),
    borderStyle: "solid",
    ...StyleSheet.padding(0, 8, 8, 12),
  },
  headerLabelText: {
    fontSize: 14,
    fontWeight: "300",
  },
});
