import React from "react";
import R14, {
  Redirect,
  CenteredForm,
  TextInputField,
  SubmitButton,
  StyleSheet,
  View,
  Text,
  SectionHeader,
  FieldGroup,
  SelectMenuField,
  Image,
  Colors,
  HiddenInputField,
} from "../core";
import { Loader } from "@googlemaps/js-api-loader";
export default R14.connect(
  class signupPaymentInfoScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.bankItemLoader = this.bankItemLoader.bind(this);
      this.addressItemLoader = this.addressItemLoader.bind(this);
      this.handleDateOfBirthMonthChangeText =
        this.handleDateOfBirthMonthChangeText.bind(this);
      this.handleDateOfBirthDayChangeText =
        this.handleDateOfBirthDayChangeText.bind(this);
      this.handleValueChange = this.handleValueChange.bind(this);
      this.product = this.props.app.dm.login.getR14Product();
      this.google = null;
      this.placesAutocompleteService = null;
      this.state = { hasAddress: false };

      this.addressHtmlRef = React.createRef();
    }
    async initPlacesAutocompleteService() {
      if (this.google) return true;
      const loader = new Loader({
        apiKey: "AIzaSyARDdj-G9-xF2-vMmm-TeSbBYP-ZfY4QLc",
        version: "weekly",
        libraries: ["places"],
      });
      this.google = await loader.load();
      this.placesAutocompleteService =
        new this.google.maps.places.AutocompleteService();
    }

    getPlacesQueryPredictions(search) {
      return search
        ? new Promise((resolve, reject) => {
            this.placesAutocompleteService.getPlacePredictions(
              {
                input: search,
                componentRestrictions: {
                  country:
                    this.props.app.dm.login.session.user.country.iso2Code,
                },
                // types: ['establishment'],
              },
              (predictions, status) => {
                try {
                  if (
                    status != this.google.maps.places.PlacesServiceStatus.OK ||
                    !predictions
                  )
                    resolve([]);

                  let values = predictions.map((p) => ({
                    label: p.description,
                    value: p.place_id,
                  }));
                  values.unshift({
                    label: search,
                    value: this.props.app.dm.login.customUserAddressKey,
                  });
                  resolve(values);
                } catch (err) {
                  reject(err);
                }
              }
            );
          })
        : [];
    }
    async addressItemLoader(filters = {}) {
      await this.initPlacesAutocompleteService();
      return await this.getPlacesQueryPredictions(filters.search);
    }
    async bankItemLoader(filters = {}) {
      filters.countryUid = {
        eq: this.props.app.dm.login.session.user.country.uid,
      };
      return await this.props.app.dm.bank.fetchSelections(filters, {
        resultsPerPage: 50,
      });
    }

    async handleSubmit(form) {
      try {
        let address = form.values.address;
        this.props.app.ui.progressIndicator.show();
        let authState = await this.props.app.dm.login.updateUserPaymentInfo(
          form.values
        );
        this.props.app.ui.progressIndicator.hide({ timeout: 750 });
        return <Redirect to='router' />;
      } catch (err) {
        console.error(err);
        switch (err.message) {
          case "AUTH_CODE_SEND_ERROR":
            form.elmts.phone.setError("Error sending SMS authentication code");
            break;
          default:
            console.error(err);
            form.addError("An unknown signup error has occurred.");
        }
      }
    }
    async handleValueChange(value, elmt, form) {
      this.setState({ hasAddress: value ? true : false });
      if (value.value === this.props.app.dm.login.customUserAddressKey) {
        let addressValues = this.props.app.dm.login.userAddressFields;
        addressValues.address1 = value;
        addressValues.country = this.props.app.dm.login.session.user.country.name;
        form.setValues(addressValues);
        return true;
      }

      var geocoder = new this.google.maps.Geocoder();
      geocoder.geocode(
        {
          placeId: value.value,
        },
        (responses, status) => {
          if (responses && responses.length) {
            let addressValues = this.parsePlaceResponse(responses[0]);
            form.setValues(addressValues);
          }
        }
      );
    }

    parsePlaceResponse(place) {
      let ret = this.props.app.dm.login.userAddressFields;
      if (!place || !place.address_components) return ret;
      let valArr = [
        { key: "route", includes: "route" },
        { key: "streetNumber", includes: "street_number" },
        { key: "address2", includes: "subpremise" },
        { key: "city", includes: "locality" },
        { key: "county", includes: "administrative_area_level_2" },
        { key: "state", includes: "administrative_area_level_1" },
        { key: "country", includes: "country" },
        { key: "postalCode", includes: "postal_code" },
      ];
      place.address_components.forEach((p) => {
        valArr
          .filter((val) => p.types.includes(val.includes))
          .forEach((val) => (ret[val.key] = p.long_name));
      });
      let addressParts = [];
      if (ret.streetNumber) {
        addressParts.push(ret.streetNumber);
        delete ret.streetNumber;
      }
      if (ret.route) {
        addressParts.push(ret.route);
        delete ret.route;
      }
      if (addressParts.length)
        ret.address1 = { label: addressParts.join(" "), value: place.place_id };

      ret.googlePlace = place;

      return ret;
    }
    handleDateOfBirthMonthChangeText(value, elmt, form) {
      if (value.length === 2) form.elmts.dateOfBirthDay.focus();
    }
    handleDateOfBirthDayChangeText(value, elmt, form) {
      if (value.length === 2) form.elmts.dateOfBirthYear.focus();
    }
    renderAddressFooter() {
      let logo = require(`../assets/images/google/google_on_non_white.png`);
      return (
        <View style={styles.addressSelectMenuFooter}>
          <Image
            resizeMode='contain'
            source={logo}
            style={styles.googleLogoImage}
          />
        </View>
      );
    }
    render() {
      return (
        <CenteredForm
          // initialValues={{
          //   firstName: "Test",
          //   lastName: "Name",
          //   phone: "917428731249",
          //   dateOfBirthMonth: "04",
          //   dateOfBirthDay: "24",
          //   dateOfBirthYear: "1982",
          //   //address: "123 Love St.",
          //   // city: "Los Angeles",
          //   // state: "California",
          //   bank: { value: "ThEQfDU3Yt", label: "Axis Bank" },
          //   aadharNumber: "3822 4546 3674",
          //   bankAccount: "97987987987987987987",
          //   pan: "BNZAA2318J",
          //   ifscCode: "HDFC0002255",
          // }}
          name='signupPaymentInfoForm'
          style={styles.centeredForm}
          headerLabel='Payment Information'
          validateBeforeSubmit
          onSubmit={this.handleSubmit}
          validators={{
            pan: (value) => {
              // Must be 10 characters long.
              // The first 5 characters must be alphabetic and uppercase.
              // The next 4 characters must be numeric.
              // The last character must be an uppercase alphabetic check digit.
              if (/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/gm.test(value)) {
                return {
                  success: true,
                  value: value,
                };
              } else return { error: "Invalid PAN." };
            },
            aadhaarNumber: (value) => {
              // Must be 12 digits long.
              // Must not begin with 0 or 1.
              value = value.replaceAll(" ", "");
              let vArr = value.match(/.{1,4}/g);
              value = vArr.join(" ");
              if (/^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/gm.test(value)) {
                return {
                  success: true,
                  value: value,
                };
              } else return { error: "Invalid Aadhaar Number." };
            },
            ifscCode: (value) => {
              // Must be 11 characters long.
              // The first 4 characters must be alphabetic and represent the bank code.
              // The 5th character must be '0'.
              // The last 6 characters can be alphanumeric and represent the branch code.
              if (/^[A-Z]{4}0[A-Z0-9]{6}$/gm.test(value)) {
                return {
                  success: true,
                  value: value,
                };
              } else return { error: "Invalid IFSC Code." };
            },
            dateOfBirthMonth: (value, options, element, form) => {
              let ret = { error: "Invalid Date", value };
              let day = form.elmts.dateOfBirthDay.value;
              let year = form.elmts.dateOfBirthYear.value;
              if (value.length === 1) value = `0${value}`;
              if (day.length === 1) day = `0${day}`;
              let dateStr = `${value}/${day}/${year}`;
              if (
                /^((0[13578]|1[02])(\/)(0[1-9]|[12][0-9]|3[01])(\/)(18|19|20)[0-9]{2})|((0[469]|11)(\/)(0[1-9]|[12][0-9]|30)(\/)(18|19|20)[0-9]{2})|((02)(\/|-|\.)(0[1-9]|1[0-9]|2[0-8])(\/|-|\.)(18|19|20)[0-9]{2})|((02)(\/|-|\.)29(\/|-|\.)(((18|19|20)(04|08|[2468][048]|[13579][26]))|2000))$/gm.test(
                  dateStr
                )
              ) {
                let d = new Date(dateStr);
                if (
                  d.getMonth() + 1 === parseInt(value) &&
                  d.getDate() === parseInt(day) &&
                  d.getFullYear() === parseInt(year)
                ) {
                  let cDate = new Date();
                  d = d.setFullYear(d.getFullYear() + 18);

                  if (d > cDate) ret = { error: "Must be 18" };
                  else
                    ret = {
                      success: true,
                      value: value,
                    };
                }
              }
              return ret;
            },
            dateOfBirthDay: (value) => {
              if (value.length === 1) value = `0${value}`;
              return {
                success: true,
                value: value,
              };
            },
            phone: async (value) => {
              return await this.props.app.dm.country.validatePhone(
                this.props.app.dm.login.session.user.country.iso2Code,
                value
              );
            },
          }}
          controlsBottomRight={[
            // <Button
            //   title="Forgot Password"
            //   variant="text"
            //   color={Colors.secondary}
            //   to='forgotPassword'
            //   key='forgotPassword'
            // />,
            <SubmitButton title='Continue' key='submit' />,
          ]}
        >
          <View style={styles.infoTextView}>
            <Text>
              Please enter your payment information for receiving earnings
              below. You will then be asked to verify your mobile phone before
              approval.
            </Text>
          </View>
          <SectionHeader level={3}>General</SectionHeader>
          <FieldGroup>
            <TextInputField
              name='firstName'
              label='First Name'
              autoFocus
              required='Enter First Name'
            />
            <TextInputField
              name='lastName'
              label='Last Name'
              required='Enter Last Name'
            />
          </FieldGroup>
          <TextInputField
            name='phone'
            label='Mobile Phone'
            required='Enter Mobile Phone'
            validator='phone'
            helper='International format starting with "+".'
          />
          <FieldGroup label='Date of Birth'>
            <TextInputField
              name='dateOfBirthMonth'
              label='MM'
              validator='dateOfBirthMonth'
              required='Enter MM'
              onChangeText={this.handleDateOfBirthMonthChangeText}
            />
            <TextInputField
              name='dateOfBirthDay'
              label='DD'
              validator='dateOfBirthDay'
              required='Enter DD'
              onChangeText={this.handleDateOfBirthDayChangeText}
            />
            <TextInputField
              name='dateOfBirthYear'
              label='YYYY'
              required='Enter YYYY'
            />
          </FieldGroup>

          <SectionHeader level={3}>Address</SectionHeader>
          {/* <TextInputField
            name='address'
            label='Address'
            required='Enter Address'
          />
          <TextInputField name='city' label='City' required='Enter City' />
          <TextInputField
            name='state'
            label='State / Province'
            required='Enter State / Province'
          /> */}
          <HiddenInputField name='googlePlace' />
          <SelectMenuField
            name='address1'
            label='Street Address'
            itemLoader={this.addressItemLoader}
            searchable
            autoLoad
            onValueChange={this.handleValueChange}
            FooterComponent={this.renderAddressFooter()}
            required='Please select an address'
            controlIcon='search'
            disableControlAnimation
          />
          {/* <TextInputField
            name='address1'
            label='Street Address'
            required='Please enter an address.'
            autoSuggest
            suggestionLoader={this.addressSuggestionLoader}
            suggestionValueFormatter={this.addressSuggestionValueFormatter}
            onSelectSuggestion={this.addressSelectSuggestionHandler}
          /> */}
          <TextInputField
            name='address2'
            label='Street Address 2'
            helper='Optional'
          />
          <TextInputField
            name='city'
            label='City'
            required='Please enter an city.'
          />
          <TextInputField
            name='county'
            label='County / District'
            helper='Optional'
          />
          <TextInputField
            name='state'
            label='State / Province / Region'
            required='Please enter an State / Province / Region.'
          />
          <TextInputField
            name='postalCode'
            label='ZIP / Postal Code'
            helper='Optional'
          />
          <TextInputField name='country' label='Country' disabled />

          <SectionHeader level={3}>Banking</SectionHeader>
          <SelectMenuField
            name='bank'
            label='Bank Name'
            itemLoader={this.bankItemLoader}
            searchable
            autoLoad
            required='Please select a bank.'
          />
          <TextInputField
            name='bankAccount'
            label='Bank Account Number'
            //validator='bankAccount'
            required='Please enter bank account number.'
          />
          <TextInputField
            name='ifscCode'
            label='IFSC Code'
            validator='ifscCode'
            required='IFSC Code'
          />
          <TextInputField
            name='pan'
            label='PAN'
            validator='pan'
            required='Enter PAN'
          />
          <TextInputField
            name='aadharNumber'
            label='Aadhar Number'
            validator='aadhaarNumber'
            required='Enter aadhar Number'
          />
        </CenteredForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  centeredForm: {
    width: 400,
    minWidth: 400,
    screen: ({ width }) => {
      if (width <= 320)
        return {
          width: "100%",
        };
    },
  },
  infoTextView: {
    paddingBottom: 24,
  },
  addressSelectMenuFooter: {
    ...StyleSheet.padding(8, 16, 8, 16),
    marginBottom: -8,
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.03),
  },
  googleLogoImage: {
    marginLeft: "auto",
    height: 16,
    width: 56,
    backgroundPosition: "center",
    flex: 0,
  },
});
