import React from "react";
import R14, { Redirect } from "./core";
import ErrorScreen from "./screens/ErrorScreen";
import LoginScreen from "./screens/LoginScreen";
import SignupScreen from "./screens/SignupScreen";
import SignupPaymentInfoScreen from "./screens/SignupPaymentInfoScreen";
import VerifyScreen from "./screens/VerifyScreen";
// import LoginVerifyScreen from "./screens/LoginVerifyScreen";
import DeploymentsScreen from "./screens/DeploymentsScreen";
import SignupPendingApprovalScreen from "./screens/SignupPendingApprovalScreen";
import AccountPasswordEditScreen from "./screens/AccountPasswordEditScreen";

export default class Actions extends R14.Actions {
  async shouldActionLoad({ to, from, app }) {
    if (to.metadata.public) return true;
    else if (
      to.metadata.signup &&
      app.dm.login.authState === app.dm.login.AUTH_STATE_SIGNUP
    )
      return true;
    else if (
      to.name === "accountPasswordEdit" &&
      app.dm.login.authState === app.dm.login.AUTH_STATE_UPDATE_PASSWORD
    )
      return true;
    else if (!app.dm.login.isLoggedIn) return <Redirect to='login' />;
    return true;
  }
  async error() {
    return <ErrorScreen />;
  }

  async forbidden() {
    return (
      <ErrorScreen error='Access is forbidden. Please check with administrator.' />
    );
  }

  /** User login actions */
  async router({ f = null }) {
    let to = "login";
    let forwardUrl = null;
    switch (this.dm.login.authState) {
      case this.dm.login.AUTH_STATE_LOGGED_IN:
        let r14Deployments = this.dm.login.session.r14Deployments;
        if (!r14Deployments.length)
          throw new Error("No deployment data found.");
        else if (r14Deployments.length === 1) {
          forwardUrl = `${r14Deployments[0].url}/auth`;
        } else to = "deployments";
        break;
      case this.dm.login.AUTH_STATE_VERIFY_MFA_CODE:
        to = "loginVerify";
        break;
      case this.dm.login.AUTH_STATE_UPDATE_PASSWORD:
        to = "accountPasswordEdit";
        break;
      case this.dm.login.AUTH_STATE_SIGNUP:
        switch (this.dm.login.session.user.state) {
          case this.dm.login.USER_STATE_SIGNUP_VERIFY_EMAIL:
            to = "signupVerifyEmail";
            break;
          case this.dm.login.USER_STATE_SIGNUP_PAYMENT_INFO:
            to = "signupPaymentInfo";
            break;
          case this.dm.login.USER_STATE_SIGNUP_VERIFY_PHONE:
            to = "signupVerifyPhone";
            break;
          case this.dm.login.USER_STATE_SIGNUP_PENDING_APPROVAL:
            to = "signupPendingApproval";
            break;
          default:
            to = "login";
        }
        break;
      default:
        to = "login";
    }
    if (forwardUrl) {
      this.nav.toUrl(forwardUrl);
      return null;
    }
    return <Redirect to={to} />;
  }

  /** User login actions */
  async login({ f = null }) {
    if (this.dm.login.isLoggedIn) return <Redirect to='deployments' />;
    return <LoginScreen f={f} />;
  }

  async loginVerify({ f = null }) {
    // Check that it is the correct auth state
    if (this.dm.login.authState !== this.dm.login.AUTH_STATE_VERIFY_MFA_CODE)
      return <Redirect to='login' />;
    return <VerifyScreen f={f} />;
  }

  async deployments({ f = null }) {
    return <DeploymentsScreen />;
  }

  async accountPasswordEdit({ f = null }) {
    return <AccountPasswordEditScreen />;
  }

  /** User signup actions */
  async signup({ f = null }) {
    if (this.dm.login.isLoggedIn) return <Redirect to='deployments' />;
    return <SignupScreen />;
  }
  async signupVerifyEmail() {
    return <VerifyScreen />;
  }
  async signupVerifyPhone() {
    return <VerifyScreen />;
  }
  async signupPaymentInfo() {
    return <SignupPaymentInfoScreen />;
  }
  async signupPendingApproval() {
    return <SignupPendingApprovalScreen />;
  }
}
